import type { GetServerSideProps, NextPage } from "next";
import React, { useMemo } from "react";
import ArticlesList from "../components/articles-list";
import CategoriesList from "../components/categories-list";
import Layout from "../components/layout";
import loadCategories, { Section } from "../lib/load-categories";
import loadPopularArticles, { Article } from "../lib/load-popular-articles";
import styles from "../styles/general.module.css";
import getAppSettings, { AppSettings } from "../lib/get-app-settings";
import getBaseUrl from "../lib/get-base-url";
import { ForbiddenError, NotFoundError, UnauthorizedError } from "../lib/api";
import {
  THCSessionDetails,
  getSessionDetailsFromRequest,
  saveSessionDetailsToLocalStorage,
} from "../lib/hc-session";
import getObjectByAlias from "../lib/get-object-by-alias";
import loadCustomer from "../lib/load-customer";
import Customer, { TCustomer } from "../models/customer";
import t from "../texts/t";

export type CompanyPageProps = {
  appSettings: AppSettings;
  popularArticles: Article[];
  allCategories: Section[];
  categories: Section[];
  customerData: TCustomer | null;
  hcSessionDetails: THCSessionDetails;
};

const CompanyPage: NextPage<CompanyPageProps> = ({
  appSettings,
  popularArticles,
  allCategories,
  categories,
  customerData,
  hcSessionDetails,
}) => {
  const baseUrl = useMemo(() => getBaseUrl(appSettings), [appSettings]);

  React.useEffect(() => {
    if (hcSessionDetails && appSettings) {
      saveSessionDetailsToLocalStorage(appSettings, hcSessionDetails);
    }
  }, [hcSessionDetails, appSettings]);

  const customer = React.useMemo(
    () => customerData && new Customer(customerData),
    [customerData]
  );

  return (
    <Layout appSettings={appSettings} hasTitle customer={customer}>
      {categories.length || popularArticles.length ? (
        <React.Fragment>
          {categories.length ? (
            <React.Fragment>
              <div
                className={styles.companyPageTitle}
                lang={appSettings.language}
              >
                {t("Browse by category", appSettings.language)}
              </div>
              <CategoriesList
                className={styles.companyPageContent}
                categories={categories}
                baseUrl={baseUrl}
              />
            </React.Fragment>
          ) : null}
          {popularArticles.length ? (
            <React.Fragment>
              <div
                className={styles.companyPageTitle}
                lang={appSettings.language}
              >
                {t("Popular articles", appSettings.language)}
              </div>
              <ArticlesList
                className={styles.companyPageContent}
                articles={popularArticles}
                withCategoryIcons={allCategories}
                baseUrl={baseUrl}
              />
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : (
        <p className={styles.emptyListMessage}>
          {t("No data", appSettings.language)}
        </p>
      )}
    </Layout>
  );
};

export default CompanyPage;

export const getServerSideProps: GetServerSideProps<CompanyPageProps> = async (
  context
) => {
  // this is nearly always the first function
  // to be called when the user opens the help center
  // since CNAME -> is mapped to -> /[companySlug]
  const appSettings = await getAppSettings(context);

  if (!appSettings) {
    return { redirect: { statusCode: 302, destination: "/" } };
  }

  const pathSansSearchParams = context.resolvedUrl.split("?")[0];
  if (pathSansSearchParams !== "/") {
    try {
      const object = await getObjectByAlias(
        appSettings.companyId,
        context.resolvedUrl,
        context
      );

      if (object) {
        return {
          redirect: { statusCode: 302, destination: object.path },
        };
      }
    } catch (error: any) {
      // Fall through
    }
  }

  // initialize hc-session-details
  const hcSessionDetails = await getSessionDetailsFromRequest(
    context,
    appSettings
  );

  try {
    const cookieKey = `atlasIdentity${appSettings.appId}`;
    const currentCustomerId = context.req.cookies[cookieKey];

    const [allCategories = [], popularArticles, customerData] =
      await Promise.all([
        loadCategories(appSettings.slug, false, context),
        loadPopularArticles(appSettings.slug, context),
        currentCustomerId
          ? loadCustomer(appSettings.appId, currentCustomerId).catch(
              (error) => {
                console.error(error);
                return null;
              }
            )
          : null,
      ]);

    const props: CompanyPageProps = {
      appSettings: appSettings,
      popularArticles,
      allCategories,
      categories: allCategories.filter((category) => !category.parentId),
      customerData,
      hcSessionDetails,
    };

    return { props };
  } catch (error: any) {
    console.error(error);
    if (
      error instanceof UnauthorizedError ||
      error instanceof ForbiddenError ||
      error instanceof NotFoundError
    ) {
      throw error;
    }
  }

  return { redirect: { statusCode: 302, destination: "/" } };
};
