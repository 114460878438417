import React from "react";
import ArticleTile, { Article } from "./article-tile";
import styles from "../styles/articles-list.module.css";
import { Section } from "../lib/section-model";
import getTopCategory from "../lib/get-top-category";

export type ArticlesListProps = {
  className?: string;
  articles: Article[];
  baseUrl: string;
  expand?: boolean;
  withCategoryIcons?: Section[];
  query?: string;
};

export default function ArticlesList({
  className,
  articles,
  baseUrl,
  expand,
  withCategoryIcons,
  query,
}: ArticlesListProps) {
  return (
    <div
      className={`${className || ""}${
        expand ? ` ${styles.articlesListExpanded}` : ""
      }`}
    >
      <ul className={styles.articlesList}>
        {articles.map((article) => {
          const subCategory = withCategoryIcons?.find(
            (c) => c.id === article.categoryId
          );
          const category =
            withCategoryIcons &&
            subCategory &&
            getTopCategory(subCategory, withCategoryIcons);
          return (
            <li key={article.slug} className={styles.articlesListItem}>
              <ArticleTile
                article={article}
                baseUrl={baseUrl}
                icon={category?.icon}
                query={query}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
