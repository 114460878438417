import React from "react";
import { Section } from "../lib/section-model";
import styles from "../styles/categories-list.module.css";
import CategoryTile from "./category-tile";

export type CategoriesListProps = {
  className?: string;
  categories: Section[];
  baseUrl: string;
};

export default function CategoriesList({
  className,
  categories,
  baseUrl,
}: CategoriesListProps) {
  return (
    <div className={className}>
      <ul className={styles.categoriesList}>
        {categories.map((category) => (
          <li key={category.slug} className={styles.categoriesListItem}>
            <CategoryTile category={category} baseUrl={baseUrl} />
          </li>
        ))}
      </ul>
    </div>
  );
}
