export type TCustomer = {
  id: string;
  firstName: string;
  lastName: string;
  isVisitor: boolean;
  email: string;
  phone: string;
  fields?: {
    photo?: string;
  };
};

class Customer {
  public id: string;
  public firstName: string;
  public lastName: string;
  public isVisitor: boolean;
  public email?: string;
  public phone?: string;
  public fields?: {
    photo?: string;
  };

  constructor(data: TCustomer) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.isVisitor = data.isVisitor;
    this.email = data.email;
    this.phone = data.phone;
    if (data.fields) {
      this.fields = {};
      if (data.fields.photo) {
        this.fields.photo = data.fields.photo;
      }
    }
  }

  get fullName(): string {
    return [this.firstName, this.lastName].filter(Boolean).join(" ");
  }
}

export default Customer;
