import { Section } from "../lib/section-model";

export default function getTopCategory(
  category: Section,
  categories: Section[]
): Section {
  if (!category.parentId) return category;
  const parent = categories.find((c) => c.id === category.parentId);
  if (parent) return getTopCategory(parent, categories);
  return category;
}
